.main {
  width: 100%;
  height: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.email-section {
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
}

.email-section span {
  margin-left: 8px;
}

.social-media-section a {
  color: #333;
  font-size: 16px;
  margin-left: 15px;
  transition: color 0.3s ease;
}

.social-media-section a:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    text-align: center;
  }

  .social-media-section a {
    margin-left: 10px;
  }
}

.navigation {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.navbar-brand {
  margin-left: 10px;
}

.navbar-brand img {
  width: 180px;
  height: auto;
}

.navbar-nav .nav-link {
  color: #333;
  font-weight: 500;
  margin-left: 10px;
  transition: color 0.3s ease;
  font-size: 17px;
}

.navbar-nav .nav-link:hover {
  color: #ff9933;
  font-size: 17px;
}

.custom-dropdown-title {
  font-weight: 500;
  color: #333;
}

.navbar-nav .dropdown-menu {
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  color: #555;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

@media (max-width: 992px) {
  .navbar-nav .nav-link {
    margin-left: 0;
    margin-top: 5px;
  }

  .navbar-brand {
    margin-left: 0;
  }

  .navigation {
    padding: 15px;
  }
}



.carousel-container {
  position: relative;
  max-width: 100%;
  height: 450px;
  overflow: hidden;
  background-color: #000;
}

.carousel-item img {
  height: 450px;
width: 100%;
  filter: brightness(0.7);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

/* Scale effect for the active slide */
.carousel-item.active img {
  transform: scale(1.05);  /* Slight zoom in on the active slide */
  opacity: 1;
}

/* Control buttons styling */
.carousel-control-prev, 
.carousel-control-next {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
}



/* Indicators styling */
.carousel-indicators li {
  background-color: #fff;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.carousel-indicators li.active {
  background-color: #ff9933;
  opacity: 1;
}



.carousel-item {
  animation: slideIn 3s ease-in-out forwards;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .carousel-container {
    height: 200px;
  }
  .carousel-item img {
    object-position: center;
    height: 200px;
  }
}




