.services-container {
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: #f4f4f4;
    margin-top: 40px;
  }
  
  .services-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0 20px;
  }
  
  .service-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .service-image {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .service-name {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }
  
  .service-description {
    font-size: 14px;
    color: #666;
    line-height: 1.6;
    max-height: 300px; /* Allows more space for content */
    overflow-y: auto; /* Scroll if content is large */
  }
  
  /* Adjust for large content in description */
  @media (max-width: 768px) {
    .service-name {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
    }
  
    .service-description {
      font-size: 0.9rem; /* Adjust font size for smaller screens */
    }
  }
  
  /* Adding responsiveness */
  @media (max-width: 600px) {
    .services-title {
      font-size: 2rem; /* Make title smaller on small screens */
    }
  
    .services-grid {
      padding: 0 10px;
    }
  
    .service-card {
      padding: 15px;
    }
  
    .service-name {
      font-size: 1.6rem; /* Make name size smaller */
    }
  
    .service-description {
      font-size: 0.85rem; /* Reduce description size for small screens */
    }
  }
  











  .contact-page {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100vh; /* Full viewport height */
    margin-top: 100px;
    padding: 10px;
  }
  
  .google-map {
    flex: 1; /* 50% width for the Google Map */
    height: 93%; /* Full height */
  }
  
  .contact-form {
    flex: 1; /* 50% width for the contact form */
    padding: 20px;
    box-sizing: border-box;
    background-color: #f4f4f4;
    margin-top: 40px;
  }
  
  .contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .contact-page {
      flex-direction: column;
    }
  
    .google-map,
    .contact-form {
      flex: none;
      height: 300px; /* Adjust for mobile screens */
    }
  
    .contact-form {
      padding: 15px;
    }
  }
  









  #footer {
    background: #f7f7f7;
    padding: 3rem;
    margin-top: 100px;
    padding-top: 7rem;
    padding-bottom: 80px;
    background-image: url(https://arena.km.ua/wp-content/uploads/3538533.jpg);
  }
  
  #footer2 {
    background: #f7f7f7;
    padding: 3rem;
    margin-top: 0px;
    padding-top: 7rem;
    padding-bottom: 80px;
  
  }
  
  .social-links h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .social-links img {
    padding-bottom: 25px;
  }
  
  .social-icons {
  
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #777777;
    margin-left: -25px;
  }
  
  .social-icons a {
  
    color: #777777;
  }
  
  .social-icons a:hover {
    color: #000;
  }
  
  .social-icons a i {
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
    padding: 0.4rem 1rem 0.4rem 1rem;
    border-radius: 3px;
    color: #82074a;
    font-size: 16px;
    margin-right: 12px;
  }
  
  li {
    list-style: none;
  }
  
  .useful-link h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .useful-link img {
    padding-bottom: 15px;
  }
  
  .use-links {
    line-height: 32px;
  }
  
  .use-links li i {
    font-size: 14px;
    padding-right: 8px;
    color: #898989;
  }
  
  .use-links li a {
    color: #303030;
    font-size: 15px;
    font-weight: 500;
    color: #777777;
  }
  
  .use-links li a:hover {
    color: #000;
  }
  
  .address h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .address img {
    padding-bottom: 15px;
  }
  
  .address-links li a {
    color: #303030;
    font-size: 15px;
    font-weight: 500;
    color: #777777;
  
  }
  
  .address-links li i {
    font-size: 16px;
    padding-right: 8px;
    color: #82074a;
  
  }
  
  .address-links li i:nth-child(1) {
    padding-top: 9px;
  }
  
  .address-links .address1 {
    font-weight: 500;
    font-size: 15px;
    display: flex;
  }
  
  .address-links {
    line-height: 32px;
    color: #777777;
  }

  .col-md-3 h2 {
    margin-left: 35px;
  }
  
  .copy-right-sec {
    padding: 1rem;
    background: #82074a;
    color: #fff;
    text-align: center;
  }
  
  .copy-right-sec a {
    color: #fcd462;
    font-weight: 500;
  }
  
  a {
    text-decoration: none;
  }
  
  










  .icon-bar {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
  }
  
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 12px;
    transition: all 0.3s ease;
    color: white;
    font-size: 15px;
    border-radius: 02px;
  }
  
  .icon-bar a:hover {
    background-color: #000;
  }
  
  .facebook {
    background-color: #316FF6; 
    color: white;
}

.twitter {
    background-color: #1DA1F2; 
    color: white;
}

.instgram  {
    background-color: #c13584; 
    color: white;
}

.youtube {
    background-color: #FF0000; 
    color: white;
}

  @media (max-width: 768px) {
    .icon-bar {
      top: 60%;
    }
  }
  
  @media (max-width: 480px) {
    .icon-bar {
      top: 60%;
    }

  
    .icon-bar a {
      display: block;
      text-align: center;
      padding: 10px;
      transition: all 0.3s ease;
      color: white;
      font-size: 12px;
    }
  }










  /* Container for vertical navigation */
.vertical-navigation {
  display: flex;
  flex-direction: column; /* Stack the buttons vertically */
  padding: 20px;
  background-color: #f4f4f4; /* Light background color */
  width: 200px; /* Adjust width as needed */
}

/* Style for each button */
.nav-btn {
  background-color: #3B5998; /* Facebook Blue for the button (change as needed) */
  color: white; /* White text color */
  padding: 10px;
  margin: 5px 0; /* Vertical margin between buttons */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Buttons take full width of container */
  transition: background-color 0.3s ease;
}

.nav-btn:hover {
  background-color: #365899; /* Darker shade on hover */
}





