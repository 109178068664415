

.Heading {
  height: 100px;
  background-image: url('https://www.w3schools.com/w3images/forest.jpg'); /* Sample image from W3Schools */
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}




.contact-container {
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.contact-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  height: 120px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #e65c00;
}

.google-map {
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    padding: 10px;
  }

  .contact-title {
    font-size: 1.5rem;
  }

  .contact-form {
    max-width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .contact-title {
    font-size: 1.2rem;
  }

  .submit-button {
    padding: 8px 16px;
  }
}






.gallery-container {
  padding: 20px;
  text-align: center;
}

.gallery-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  padding: 0 10px;
}

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .gallery-title {
    font-size: 1.5rem;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}










.event-container {
  padding: 20px;
  text-align: center;
}

.event-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  padding: 0 10px;
}

.event-item img {
  width: 100%;
  height: 350px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .event-title {
    font-size: 1.5rem;
  }

  .event-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
